<template>
  <div class="send-sms-action">
    <div
      v-if="
        product.paymentMethodType === 'Card' ||
        product.paymentMethodType === 'Betalingsservice'
      "
      class="sms-action"
    >
      <div class="control-input">
        <ul class="checkbox-group vertical">
          <li>
            <label for="sendSms">
              <input
                type="checkbox"
                id="sendSms"
                v-model="product.sendSms"
                aria-label="Afslut med SMS"
              />Complete with SMS: "{{
                sessionRequest.workflow.checkoutMessage
              }}
              {{
                sessionRequest.workflow.urlShortener
                  | humanReadableUrlShortener
              }}"
              <div class="check"></div>
            </label>
          </li>
        </ul>
      </div>

      <!-- msisdn for SMS -->
      <div v-if="product.sendSms && !getContactPhone" class="row">
        <div class="col-sm-6 control-input">
          <label for="msisdn">Enter phone for SMS</label>
          <input
            type="number"
            id="msisdn"
            class="uik-input__input"
            placeholder="Insert phone"
            minlength="6"
            maxlength="14"
            autocomplete="nope"
            required
            v-model.trim="product.msisdn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "OnboardingSendSms",
  mixins: [formats],
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    getContactPhone: {
      required: false,
    },
  },
});
</script>
