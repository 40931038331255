<template>
  <div v-if="
    product.paymentType === 'Single' || product.paymentType === 'Recurring'
  " class="block">
    <!-- Tooltip -->
    <div class="control-input">
      <label>Payment method types allowed</label>
      <tool-tip v-bind:tooltip="'Please select the relevant payment method types'" />
    </div>

    <ul class="checkbox-group payment-method-type vertical">
      <!-- Test -->
      <li>
        <label v-bind:for="'paymentMethodTypeTest' + product.uuid">
          <input type="checkbox" v-bind:id="'paymentMethodTypeTest' + product.uuid"
            v-model="product.paymentMethodTypeTest" />Test
          <div class="check"></div>
          <div class="check-wrap"></div>
          <i class="payment-icon"></i>
        </label>
      </li>

      <!-- Card -->
      <li>
        <label v-bind:for="'paymentMethodTypeCard' + product.uuid">
          <input type="checkbox" v-bind:id="'paymentMethodTypeCard' + product.uuid"
            v-model="product.paymentMethodTypeCard" />Card
          <div class="check"></div>
          <div class="check-wrap"></div>
          <i class="payment-icon"></i>
        </label>
      </li>

      <!-- MobilePay -->
      <li v-if="product.currencyCode === 'DKK' || product.currencyCode === 'EUR'">
        <label v-bind:for="'paymentMethodTypeMobilePay' + product.uuid">
          <input type="checkbox" v-bind:id="'paymentMethodTypeMobilePay' + product.uuid"
            v-model="product.paymentMethodTypeMobilePay" />MobilePay
          <div class="check"></div>
          <div class="check-wrap"></div>
          <i class="payment-icon"></i>
        </label>
      </li>

      <!-- Betalingsservice -->
      <li v-if="
        product.currencyCode === 'DKK' && product.paymentType == 'Recurring'
      ">
        <label v-bind:for="'paymentMethodTypeBetalingsservice' + product.uuid">
          <input type="checkbox" v-bind:id="'paymentMethodTypeBetalingsservice' + product.uuid"
            v-model="product.paymentMethodTypeBetalingsservice" />Betalingsservice
          <div class="check"></div>
          <div class="check-wrap"></div>
          <i class="payment-icon"></i>
        </label>
      </li>

      <!-- Giro -->
      <li v-if="product.currencyCode === 'DKK'">
        <label v-bind:for="'paymentMethodTypeGiro' + product.uuid">
          <input type="checkbox" v-bind:id="'paymentMethodTypeGiro' + product.uuid"
            v-model="product.paymentMethodTypeGiro" />Giro
          <div class="check"></div>
          <div class="check-wrap"></div>
          <i class="payment-icon"></i>
        </label>
      </li>

      <!-- Invoice -->
      <li>
        <label v-bind:for="'paymentMethodTypeInvoice' + product.uuid">
          <input type="checkbox" v-bind:id="'paymentMethodTypeInvoice' + product.uuid"
            v-model="product.paymentMethodTypeInvoice" />Invoice
          <div class="check"></div>
          <div class="check-wrap"></div>
          <i class="payment-icon"></i>
        </label>
      </li>

      <!-- EAN -->
      <li v-if="product.currencyCode === 'DKK'">
        <label v-bind:for="'paymentMethodTypeEAN' + product.uuid">
          <input type="checkbox" v-bind:id="'paymentMethodTypeEAN' + product.uuid"
            v-model="product.paymentMethodTypeEAN" />EAN
          <div class="check"></div>
          <div class="check-wrap"></div>
          <i class="payment-icon"></i>
        </label>
      </li>

      <!-- Direct debit -->
      <li v-if="product.currencyCode === 'ISK'">
        <label v-bind:for="'paymentMethodTypeDirectDebit' + product.uuid">
          <input type="checkbox" v-bind:id="'paymentMethodTypeDirectDebit' + product.uuid"
            v-model="product.paymentMethodTypeDirectDebit" />Direct debit
          <div class="check"></div>
          <div class="check-wrap"></div>
          <i class="payment-icon"></i>
        </label>
      </li>

    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import ToolTip from "../ToolTip";

export default Vue.extend({
  name: "WorkflowModalPaymentMethodTypes",
  components: {
    ToolTip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
});
</script>
