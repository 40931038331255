<template>
  <main class="container frontend">
    <!-- Verify Campaign -->
    <template v-if="!this.$route.query.campaignGuid">
      <div class="alert alert-danger" role="alert">
        Campaign Guid not defined.
      </div>
    </template>

    <!-- Verify instance -->
    <template v-else-if="this.instanceErrored">
      <div class="alert alert-danger" role="alert">
        An error occured. Please refresh and try again.
      </div>
    </template>

    <!-- Verify user by role -->
    <template v-else-if="this.instanceVerified">
      <!-- Message to merchants -->
      <div v-if="this.$root.user.role === 'MerchantAdmin'" class="alert alert-info" role="alert">
        Please note! As a merchant you can only review the campaign and the
        workflow. Not start sessions.
      </div>

      <!-- Verify session -->
      <template v-if="this.campaignErrored">
        <div v-if="this.campaignErrorDescription" class="alert alert-danger" role="alert">
          {{ this.campaignErrorDescription }}
        </div>
        <div v-else class="alert alert-danger" role="alert">
          The campaign is not available.
        </div>
      </template>
      <template v-else-if="this.workflowErrored">
        <div class="alert alert-danger" role="alert">
          The workflow is not available.
        </div>
      </template>
      <template v-else-if="this.campaignLoading">Loading campaign ...</template>
      <template v-else-if="this.workflowLoading">Loading workflow ...</template>

      <!-- Verified -->
      <template v-else>
        <!-- Summarise session -->
        <section v-if="
          this.$root.user.role === 'AgencyManager' ||
          this.$root.user.role === 'SeniorAgent' ||
          this.$root.user.role === 'JuniorAgent'
        " class="session-summary" aria-hidden="true">
          <ul>
            <li v-if="this.$root.merchant.name">
              <span>Organisation</span> {{ this.$root.merchant.name }}
            </li>
            <li v-else-if="this.sessionRequest.merchantId">
              <span>Organisation</span> {{ this.sessionRequest.merchantId }}
            </li>
            <li v-if="this.$root.user.name">
              <span>Agent:</span> {{ this.$root.user.name }}
            </li>
            <li v-else-if="this.sessionRequest.userGuid">
              <span>Agent:</span> {{ this.sessionRequest.userGuid }}
            </li>
            <li v-if="this.$route.query.externalLeadId">
              <span>Lead:</span> {{ this.$route.query.externalLeadId }}
            </li>
            <li v-if="this.sessionRequest.campaign.name">
              <span>Campaign:</span> {{ this.sessionRequest.campaign.name }}
            </li>
            <li v-if="this.sessionRequest.workflow.name">
              <span>Workflow<template v-if="this.sessionRequest.workflow.countryCode"> ({{
                this.sessionRequest.workflow.countryCode
              }})</template>:</span> {{ this.sessionRequest.workflow.name }}
            </li>
            <li v-if="this.sessionRequest.workflow.serviceType">
              <span>Type:</span> {{ this.sessionRequest.workflow.serviceType | humanReadableServiceType }}
            </li>
          </ul>
          <p v-if="this.sessionRequest.campaign.description">
            {{ this.sessionRequest.campaign.description }}
          </p>
          <hr />
        </section>

        <!-- Verify External Lead ID for SignUp -->
        <div v-if="
          this.sessionRequest.workflow.serviceType === 'SignUp' &&
          !this.$route.query.externalLeadId
        ">
          <div class="alert alert-warning" role="alert">
            External lead ID is not defined.
          </div>
          <p aria-hidden="true">
            Please prepend a unique external ID per Lead to your URL like so:<br />
            {{ this.getAppUrl() }}/onboard?campaignGuid={{
              this.$route.query.campaignGuid
            }}&<u>externalLeadId={ ID }</u>
          </p>
          <hr />
          <p aria-hidden="true">
            If the lead is not registered elsewhere, you can proceed with a
            random external lead ID.
          </p>
          <button type="button" class="uik-btn__base btn btn-primary" v-on:click="defineExternalLeadId()"
            aria-label="Klik for at definere et tilfældig ID.">
            Define a random external lead ID
          </button>
        </div>
        <!-- Verify Subscription Guid for Upgrade -->
        <div v-else-if="
          this.sessionRequest.workflow.serviceType === 'Upgrade' &&
          !this.$route.query.subscriptionGuid
        ">
          <div class="alert alert-danger" role="alert">
            Subscription Guid is not defined.
          </div>
          <p>
            Please prepend the correct subscriptionGuid to your URL like so:<br />
            {{ this.getAppUrl() }}/onboard?campaignGuid={{
              this.$route.query.campaignGuid
            }}&<u>subscriptionGuid={ guid }</u>
          </p>
        </div>

        <!-- Verified -->
        <div v-else class="session-specification" v-bind:class="{
          'only-button': this.$route.query.onlyButton === 'true',
        }">
          <form name="onboarding" method="post" action="" autocomplete="nope" novalidate="novalidate"
            class="form-horizontal" v-on:submit.prevent>
            <!-- Information -->
            <section class="information group-wrap" role="region" aria-label="Kontaktinformationer" v-bind:class="{
              collapsed: informationCollapsed,
              hidden: this.$route.query.onlyButton === 'true',
            }" v-bind:aria-hidden="this.$route.query.onlyButton === 'true'">
              <!-- Collapse / Expand trigger -->
              <div class="group-header" role="expand">
                <h3>Information</h3>
                <button type="button" v-bind:aria-label="informationCollapsed
                  ? 'Tryk Enter for at åbne området og ændre på kontaktinformationer.'
                  : 'Tryk Enter for at lukke området.'
                  " v-on:click="toggleInformationCollapsed()">
                  <i class="expand-icon"></i>
                </button>

                <!-- Screen reader info -->
                <div role="status" v-bind:aria-label="informationCollapsed ? 'Lukket' : 'Åbnet'" class="sr-only"></div>
              </div>
              <!-- Collapse / Expand content -->
              <div class="group-content clearfix" v-bind:aria-hidden="informationCollapsed ? true : false">
                <!-- Contact data -->
                <onboarding-contact-data ref="OnboardingContactData" v-bind:sessionRequest="this.sessionRequest"
                  v-bind:contactDataApplied="this.contactDataApplied" />

                <!-- Meta data -->
                <onboarding-meta-data ref="OnboardingMetaData" v-bind:sessionRequest="this.sessionRequest" />
              </div>
            </section>

            <!-- SignUp -->
            <onboarding-sign-up v-if="this.sessionRequest.workflow.serviceType === 'SignUp'" ref="OnboardingSignUp"
              v-bind:sessionRequest="this.sessionRequest" v-on:newSessionStatus="setSessionStatus"
              v-on:onSignUpProductChanged="updateOnboarding" />

            <!-- Upgrade & Retention -->
            <onboarding-upgrade v-else-if="this.sessionRequest.workflow.serviceType === 'Upgrade'"
              ref="OnboardingUpgrade" v-bind:sessionRequest="this.sessionRequest"
              v-on:newSessionStatus="setSessionStatus" />
          </form>
        </div>
      </template>
    </template>

    <!-- Default -->
    <template v-else>
      <div role="alert" aria-hidden="true">Loading ...</div>
    </template>
  </main>
</template>

<script>
import Vue from "vue";
import OnboardingSignUp from "./OnboardingSignUp";
import OnboardingUpgrade from "./OnboardingUpgrade";
import OnboardingContactData from "./OnboardingContactData";
import OnboardingMetaData from "./OnboardingMetaData";
import { sessionRequestHandler } from "../../mixins/sessionRequestHandler.js";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "Onboarding",
  mixins: [sessionRequestHandler, formats],
  components: {
    OnboardingSignUp,
    OnboardingUpgrade,
    OnboardingContactData,
    OnboardingMetaData,
  },
  data: function () {
    return {
      contactDataApplied: false,
      sessionStatus: null,
      instanceVerified: false,
      instanceErrored: false,
      informationCollapsed: false,
    };
  },
  created() {
    var checks = 0;

    //  Queue sessionRequest while account is being verified
    let verifiedFrontendSession = setInterval(
      function () {
        checks++;
        if (this.verifyInstance()) {
          this.instanceVerified = true;
          clearInterval(verifiedFrontendSession);
        } else if (checks > 5) {
          this.instanceErrored = true;
          clearInterval(verifiedFrontendSession);
        }
      }.bind(this),
      1000 // one second
    );

    //  Collapse information if defined by subscription
    if (this.sessionRequest.workflow.serviceType === "Upgrade") {
      this.informationCollapsed = true;
    }

    //  Collapse information if defined by query
    else if (
      this.$route.query.subscriptionGuid ||
      this.$route.query.pre_subscriptionGuid ||
      this.$route.query.contactGuid ||
      this.$route.query.pre_contactGuid ||
      this.$route.query.pre_name ||
      this.$route.query.pre_firstName ||
      this.$route.query.pre_lastName ||
      this.$route.query.pre_msisdn
    ) {
      this.informationCollapsed = true;
    }
  },
  methods: {
    /**
     * Set and define External Lead ID
     */
    defineExternalLeadId: function () {
      var href = window.location.href;

      window.location =
        href.replace("&externalLeadId=", "") +
        "&externalLeadId=" +
        this.getRandomString();
    },

    /**
     * Get app url
     */
    getAppUrl: function () {
      if (this.$root.apiUrl.includes("development")) {
        return "https://development.onboarding.onlinefundraising.dk";
      } else if (this.$root.apiUrl.includes("sandbox")) {
        return "https://sandbox.onboarding.onlinefundraising.dk";
      } else {
        //  return "https://allears.onboarding.onlinefundraising.dk";
        return "https://onboarding.onlinefundraising.dk";
      }
    },

    /**
     * Get random string
     * @param {int} length
     */
    getRandomString: function (length = 10) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    /**
     * Force update
     */
    updateOnboarding: function () {
      window.console.log("updateOnboarding triggered");
      this.$forceUpdate();
    },

    /**
     * Re-initialise now Contact's data have been applied
     */
    contactApplied: function () {
      this.contactDataApplied = true;
    },

    /**
     *  Toggle expand / collapse
     */
    toggleInformationCollapsed: function () {
      if (this.informationCollapsed) {
        this.informationCollapsed = false;
      } else {
        this.informationCollapsed = true;
      }
    },

    /**
     * Set sessionStatus from emit
     * @param {string} sessionStatus
     */
    setSessionStatus: function (sessionStatus) {
      if (sessionStatus) {
        this.sessionStatus = sessionStatus;
      }
    },
  },
});
</script>
