import { formats } from "./formats.js";

export const sessionRequestDataHandler = {
    mixins: [formats],
    methods: {

        /**
         * Define externalLeadId
         * @param {object} request
         */
        filterLeadId: function (request) {

            if (this.sessionRequest.externalLeadId) {
                request.externalLeadId = this.sessionRequest.externalLeadId;
            } else if (this.sessionRequest.subscriptionData.subscriptionGuid) {
                request.externalLeadId = this.sessionRequest.subscriptionData.subscriptionGuid;
            }

            return request;
        },

        /**
         * Define metaData
         * @param {object} request 
         * @param {object} product 
         */
        filterMetaData: function (request) {

            request.metaData = {};

            //  Define metaData
            if (Object.keys(this.sessionRequest.metaData).length > 0) {

                //  Extract key and value
                for (var i = 0; i < this.sessionRequest.metaData.length; i++) {
                    if (this.sessionRequest.metaData[i].value) {
                        request.metaData[
                            this.sessionRequest.metaData[i].name
                        ] = this.sessionRequest.metaData[i].value;
                    }

                    //  Fail on missing required value
                    else if (this.sessionRequest.metaData[i].required) {
                        request.error = this.sessionRequest.metaData[i].label + ' is required';
                        return request;
                    }
                }
            }

            return request;
        },

        /**
         * Define contactData
         * @param {object} request 
         * @param {object} product 
         */
        filterContactData: function (request, product) {

            request.contactData = {};

            //  Set contactGuid
            if (this.sessionRequest.contactGuid) {
                request.contactData.contactGuid = this.sessionRequest.contactGuid;
            }

            //  Define contactData
            if (this.sessionRequest.workflow.contactDataFields && Object.keys(this.sessionRequest.workflow.contactDataFields).length > 0) {

                //  Extract key and value
                for (var o = 0; o < this.sessionRequest.workflow.contactDataFields.length; o++) {

                    let key = this.sessionRequest.workflow.contactDataFields[o].name;

                    //  Verify value
                    if (
                        this.sessionRequest.workflow.contactDataFields[o].value &&
                        this.sessionRequest.workflow.contactDataFields[o].value !== ''
                    ) {

                        //  Fail on invalid value
                        if (this.sessionRequest.workflow.contactDataFields[o].validity === 'error') {
                            request.error = this.sessionRequest.workflow.contactDataFields[o].label + ' seems invalid. Please review';
                            return request;
                        }

                        //  Add value from DAWA
                        else if (key === 'dawaAddress') {
                            request.contactData[
                                'address'
                            ] = this.sessionRequest.workflow.contactDataFields[o].value;

                            //  Append DAWA validated address despite contact fields might not appear in the Workflow
                            if (this.sessionRequest.workflow.contactDataFields[o].hasOwnProperty('dawaAddress2')) {
                                request.contactData['address2'] = this.sessionRequest.workflow.contactDataFields[o].dawaAddress2;
                            }
                            if (this.sessionRequest.workflow.contactDataFields[o].hasOwnProperty('dawaPostCode')) {
                                request.contactData['postCode'] = this.sessionRequest.workflow.contactDataFields[o].dawaPostCode;
                            }
                            if (this.sessionRequest.workflow.contactDataFields[o].hasOwnProperty('dawaCity')) {
                                request.contactData['city'] = this.sessionRequest.workflow.contactDataFields[o].dawaCity;
                            }
                            if (this.sessionRequest.workflow.contactDataFields[o].hasOwnProperty('dawaCountryCode')) {
                                request.contactData['countryCode'] = this.sessionRequest.workflow.contactDataFields[o].dawaCountryCode;
                            }
                        }

                        //  Add value
                        else {
                            request.contactData[
                                key
                            ] = this.sessionRequest.workflow.contactDataFields[o].value;
                        }
                    }

                    //  Fail on missing required value
                    else if (this.sessionRequest.workflow.contactDataFields[o].required) {
                        request.error = this.sessionRequest.workflow.contactDataFields[o].label + ' is required. Please define';
                        return request;
                    }
                }

                //  Combine name parts
                if (request.contactData.firstName && request.contactData.lastName) {
                    request.contactData.name = request.contactData.firstName + ' ' + request.contactData.lastName;
                }

                //  Assume DK, if full address is given and countryCode is not set
                if (!request.contactData.countryCode && request.contactData.address && request.contactData.postCode && request.contactData.city) {
                    request.contactData.countryCode = 'DK'; // TODO - change this assumption
                }

                //  Filter MSISDN
                request = this.filterMsisdn(request, product);

                //  Ensure nationalId, when filled in late
                if (!request.contactData.nationalId && product.nationalId) {
                    request.contactData.nationalId = product.nationalId;
                }

                //  Ensure businessCode, when filled in late
                if (!request.contactData.businessCode && product.businessCode) {
                    request.contactData.businessCode = product.businessCode;
                }

                //  Ensure eanNumber, when filled in late
                if (!request.contactData.eanNumber && product.eanNumber) {
                    request.contactData.eanNumber = product.eanNumber;
                }
            }

            return request;
        },

        /**
         * Validate contactData
         * @param {object} request
         */
        validateContactData: function (request) {

            //  Skip if error detected previously
            if (request.error) {
                return request;
            }

            //  EAN
            if (request.contactData.eanNumber && !this.isEanNumberValid(request.contactData.eanNumber)) {
                request.error = 'EAN number is invalid. Please review';
                return request;
            }

            return request;
        },

        /**
         * Define formResult
         * @param {object} request 
         * @param {object} product 
         */
        filterFormResult: function (request, product) {

            request.formResult = {};

            //  Set service type
            request.formResult["serviceType"] = request.serviceType;

            //  contactData
            if (request.contactData) {
                for (var a in request.contactData) {
                    request.formResult[a] = request.contactData[a];
                }

                //  supply msisdn as phone
                if (request.contactData.msisdn) {
                    request.formResult["phone"] = request.contactData.msisdn;
                }
            }

            //  DAWA specific contactData
            if (this.sessionRequest.workflow.contactDataFields && Object.keys(this.sessionRequest.workflow.contactDataFields).length > 0) {

                //  Extract key and value
                for (var o = 0; o < this.sessionRequest.workflow.contactDataFields.length; o++) {
                    let contactData = this.sessionRequest.workflow.contactDataFields[o];
                    if (contactData.inputType === 'dawaAddress') {
                        if (contactData.dawaAddress) {
                            request.formResult["dawa_address"] = contactData.dawaAddress;
                        }
                        if (contactData.dawaAddress2) {
                            request.formResult["dawa_address2"] = contactData.dawaAddress2;
                        }
                        if (contactData.dawaPostCode) {
                            request.formResult["dawa_postCode"] = contactData.dawaPostCode;
                        }
                        if (contactData.dawaCity) {
                            request.formResult["dawa_city"] = contactData.dawaCity;
                        }
                        if (contactData.dawaMunicipalityCode) {
                            request.formResult["dawa_municipalityCode"] = contactData.dawaMunicipalityCode;
                        }
                        if (contactData.dawaCountryCode) {
                            request.formResult["dawa_countryCode"] = contactData.dawaCountryCode;
                        }
                        if (contactData.dawaId) {
                            request.formResult["dawa_id"] = contactData.dawaId;
                        }
                    }
                }
            }

            //  metaData
            if (request.metaData) {
                for (var b in request.metaData) {
                    request.formResult[b] = request.metaData[b];
                }
            }

            //  userData
            if (this.sessionRequest.user) {
                if (this.sessionRequest.user.name) {
                    request.formResult["userName"] = this.sessionRequest.user.name;
                }
                if (this.sessionRequest.user.externalId) {
                    request.formResult["userExternalId"] = this.sessionRequest.user.externalId;
                }
            }

            //  amountTotal as amount
            if (product.amountTotal) {
                request.formResult["amount"] = product.amountTotal;
            }

            //  transactional data
            let transactionalKeys = [
                "agreementGuid",
                "agreementName",
                "agreementDescription",
                "addOnName",
                "addOnDescription",
                "subscriptionGuid",
                "communicationCollectionGuid",
                "currencyCode",
                "vatPercentage",
                "paymentType",
                "paymentMethodType",
                "purposeAccountingCode",
                "paymentRequired",
                "taxDeductable"
            ];

            //  include sort code and account number in transactional data, if BS 3rd
            if (this.$root.merchant.bsByThirdParty) {
                transactionalKeys.push('sortCode');
                transactionalKeys.push('accountNumber');
            }

            //  include oneOff amount, if applicable
            if (product.paymentType === "OneOff") {
                transactionalKeys.push('oneOffAmount');
            }

            for (let c = 0; c < transactionalKeys.length; c++) {

                let d = transactionalKeys[c];
                if (product[d]) {
                    request.formResult[d] = product[d];
                }
            }

            //  payment type
            if (product.paymentType === "Single") {
                request.formResult["paymentTypeTranslated"] = "Enkelt indbetaling";
            } else if (product.paymentType === "OneOff") {
                request.formResult["paymentTypeTranslated"] = "Betalingsaftale med straksbetaling";
            } else if (product.paymentType === "Recurring") {
                request.formResult["paymentTypeTranslated"] = "Betalingsaftale";
            }

            //  payment method type
            if (product.paymentMethodType === "Card") {
                request.formResult["paymentMethodTypeTranslated"] = "Betalingskort";
            } else if (product.paymentMethodType === "MobilePaySubscriptions") {
                request.formResult["paymentMethodTypeTranslated"] = "MobilePay Subscriptions";
            } else if (product.paymentMethodType === "MobilePayOnline") {
                request.formResult["paymentMethodTypeTranslated"] = "MobilePay Online";
            } else if (product.paymentMethodType === "Betalingsservice") {
                request.formResult["paymentMethodTypeTranslated"] = "Betalingsservice";
            } else if (product.paymentMethodType === "Giro") {
                request.formResult["paymentMethodTypeTranslated"] = "Giro";
            } else if (product.paymentMethodType === "EAN") {
                request.formResult["paymentMethodTypeTranslated"] = "EAN";
            } else if (product.paymentMethodType === "Invoice") {
                request.formResult["paymentMethodTypeTranslated"] = "Faktura";
            } else if (product.paymentMethodType === "Test") {
                request.formResult["paymentMethodTypeTranslated"] = "Test";
            }

            //  upgradeAmount by AgreementUpdate
            if (request.serviceType === 'AgreementUpdate') {

                let oldAmountTotal = parseFloat(this.sessionRequest.originalAgreementData.amountTotal);
                let oldAmountTotalYearly = null;
                let oldScheduleCalendarUnit = this.sessionRequest.originalAgreementData.scheduleCalendarUnit;
                let oldScheduleEveryOther = this.sessionRequest.originalAgreementData.scheduleEveryOther;

                let newAmountTotal = parseFloat(product.amountTotal);
                let newAmountTotalYearly = null;
                let newScheduleCalendarUnit = product.scheduleCalendarUnit;
                let newScheduleEveryOther = product.scheduleEveryOther;

                //  amount difference accounting for ~52 intervals per year
                if (
                    oldScheduleCalendarUnit === 'Week' && newScheduleCalendarUnit === 'Week'
                ) {
                    oldAmountTotalYearly = oldAmountTotal * (52 / parseFloat(oldScheduleEveryOther));
                    newAmountTotalYearly = newAmountTotal * (52 / parseFloat(newScheduleEveryOther));
                }

                //  amount difference accounting for 12 intervals per year
                else if (
                    oldScheduleCalendarUnit === 'Month' && newScheduleCalendarUnit === 'Month'
                ) {
                    oldAmountTotalYearly = oldAmountTotal * (12 / parseFloat(oldScheduleEveryOther));
                    newAmountTotalYearly = newAmountTotal * (12 / parseFloat(newScheduleEveryOther));
                }

                request.formResult["upgradeAmount"] = newAmountTotal - oldAmountTotal;
                request.formResult["upgradeAmountYearly"] = newAmountTotalYearly - oldAmountTotalYearly;

                //  schedule selected set
                if (product.hasScheduleSelectedSet && request.agreementData) {
                    request.formResult["recurringSelectedSet"] = request.agreementData.scheduleSelectedSet;
                }
            }

            //  upgradeAmount by CreateAddOn
            else if (request.serviceType === 'CreateAddOn') {

                let upgradeAmountYearly = null;

                //  amount difference accounting for ~52 intervals per year
                if (this.sessionRequest.agreementData.scheduleCalendarUnit === 'Week') {
                    upgradeAmountYearly = parseFloat(product.amountTotal) * (52 / this.sessionRequest.agreementData.scheduleEveryOther);
                }

                //  amount difference accounting for 12 intervals per year
                else if (this.sessionRequest.agreementData.scheduleCalendarUnit === 'Month') {
                    upgradeAmountYearly = parseFloat(product.amountTotal) * (12 / this.sessionRequest.agreementData.scheduleEveryOther);
                }

                request.formResult["upgradeAmount"] = parseFloat(product.amountTotal);
                request.formResult["upgradeAmountYearly"] = upgradeAmountYearly;
            }

            //  transactional data with dissimilar naming convention
            if (product.paymentType === "Recurring") {

                //  Recurring start (type)
                if (product.scheduleStartType) {
                    request.formResult["recurringStart"] = product.scheduleStartType;
                }

                //  Recurring start date
                request.formResult[
                    "recurringStartDate"
                ] = request.subscriptionData.startDate;

                //  Recurring frequency
                if (product.scheduleType === "Manual") {
                    request.formResult["recurringFrequency"] = "Manual";
                    request.formResult["recurringFrequencyTranslated"] = "Gentagne";
                } else if (product.scheduleType === "Weekly") {
                    request.formResult["recurringFrequency"] = "Weekly";
                    request.formResult["recurringFrequencyTranslated"] = "Ugentligt";
                } else if (product.scheduleType === "Monthly") {
                    request.formResult["recurringFrequency"] = "Monthly";
                    request.formResult["recurringFrequencyTranslated"] = "Månedligt";
                } else if (product.scheduleType === "Quarterly") {
                    request.formResult["recurringFrequency"] = "Quarterly";
                    request.formResult["recurringFrequencyTranslated"] = "Kvartalsvist";
                } else if (product.scheduleType === "Halfyearly") {
                    request.formResult["recurringFrequency"] = "Biannually";
                    request.formResult["recurringFrequencyTranslated"] = "Halvårligt";
                } else if (product.scheduleType === "Yearly") {
                    request.formResult["recurringFrequency"] = "Yearly";
                    request.formResult["recurringFrequencyTranslated"] = "Årligt";
                } else if (product.scheduleType === "Custom") {
                    request.formResult["recurringFrequency"] = "Custom";
                    request.formResult["recurringFrequencyTranslated"] = "Tilpasset";
                }
            }

            //  Set date
            request.formResult.date = new Date().toJSON().slice(0, 19).replace("T", " ");

            //  Flag transactional in DataSet
            request.formResult.dataSetTransactional = true;

            //  Flag third party in DataSet
            if (request.serviceType === 'SignUpRecurringByBs3rd') {
                request.formResult.dataSetThirdParty = true;
            } else if (request.serviceType === 'SignUpSingleByGiro3rd') {
                request.formResult.dataSetThirdParty = true;
            } else if (request.serviceType === 'SignUpRecurringByGiro3rd') {
                request.formResult.dataSetThirdParty = true;
            } else if (request.serviceType === 'SignUpSingleByInvoice3rd') {
                request.formResult.dataSetThirdParty = true;
            } else if (request.serviceType === 'SignUpRecurringByInvoice3rd') {
                request.formResult.dataSetThirdParty = true;
            } else if (request.serviceType === 'SignUpSingleByEAN3rd') {
                request.formResult.dataSetThirdParty = true;
            } else if (request.serviceType === 'SignUpSingleByEAN3rd') {
                request.formResult.dataSetThirdParty = true;
            } else {
                request.formResult.dataSetThirdParty = false;
            }

            return request;
        },

        filterMsisdn: function (request, product) {

            let phone = null;

            //  Ensure phone as msisdn
            if (request.contactData.msisdn) {
                phone = request.contactData.msisdn;
            }

            //  Fallback if phone is only defined in relation to gateway
            else if (product.msisdn) {
                phone = product.msisdn;
            }

            if (phone) {

                let countryCode, callingCode;

                if (request.contactData.countryCode) {
                    countryCode = request.contactData.countryCode;
                } else if (this.sessionRequest.workflow.countryCode) {
                    countryCode = this.sessionRequest.workflow.countryCode
                } else {
                    countryCode = 'DK';
                }

                //  44 - United Kingdom (Great Britain)
                if (countryCode === 'GB' && phone.length === 10) {
                    callingCode = '44';
                }

                //  45 - Denmark
                else if (countryCode === 'DK' && phone.length === 8) {
                    callingCode = '45';
                }

                //  46 - Sweden
                else if (countryCode === 'SE' && phone.length === 7) {
                    callingCode = '46';
                }

                //  47 - Norway
                else if (countryCode === 'NO' && phone.length === 8) {
                    callingCode = '47';
                }

                //  49 - Germany
                else if (countryCode === 'DE' && phone.length === 10) {
                    callingCode = '49';
                }

                //  354 - Iceland
                else if (countryCode === 'IS' && phone.length === 7) {
                    callingCode = '354';
                }

                //  358 - Finland
                else if (countryCode === 'FI' && phone.length === 10) {
                    callingCode = '358';
                }

                //  298 - Greenland
                else if (countryCode === 'GL' && phone.length === 6) {
                    callingCode = '298';
                }

                //  299 - Faroe Islands
                else if (countryCode === 'FO' && phone.length === 6) {
                    callingCode = '299';
                }

                //  Apply calling code
                if (callingCode) {
                    request.contactData.msisdn = callingCode + phone;
                }
            }

            return request;
        },

        /**
         * Define formFields
         * @param {object} request
         */
        filterFormFields: function (request) {

            request.formFields = [];

            for (var field in request.formResult) {
                request.formFields.push({
                    label: field,
                    name: field,
                    value: request.formResult[field]
                });
            }

            return request;
        },

        /**
         * Append full query string
         * @param {object} request
         */
        filterQueryString: function (request) {

            //  Form result
            request.formResult["queryString"] = window.location.href;
            request.formResult["queryParams"] = JSON.stringify(this.$route.query);

            //  Form field
            request.formFields.push({
                label: 'Query string',
                name: 'queryString',
                value: window.location.href
            });

            return request;
        }
    }
}