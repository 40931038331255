import axios from "axios";
import md5 from "js-md5";
import { formats } from "./formats.js";
import { merchantHandler } from "./merchantHandler.js";
import { campaignHandler } from "./campaignHandler.js";
import { workflowHandler } from "./workflowHandler.js";
import { relayHandler } from "./relayHandler.js";
import { sessionRequestDataHandler } from "./sessionRequestDataHandler.js";
import { sessionRequestTransactionalHandler } from "./sessionRequestTransactionalHandler.js";
import { sessionRequestGatewayHandler } from "./sessionRequestGatewayHandler.js";

export const sessionRequestHandler = {
    mixins: [formats, merchantHandler, campaignHandler, workflowHandler, relayHandler, sessionRequestDataHandler, sessionRequestTransactionalHandler, sessionRequestGatewayHandler],
    props: {
        sessionRequest: {
            type: Object,
            default: function () {
                return {
                    agencyId: null,
                    merchantId: null,
                    userGuid: null,
                    user: {},
                    campaignGuid: null,
                    campaign: {},
                    workflowGuid: null,
                    workflow: {},
                    externalLeadId: null,
                    sessionGuid: null,
                    serviceType: null,
                    completionChannel: null,
                    agreementData: {},
                    subscriptionGuid: null,
                    subscriptionData: {},
                    paymentMethodGuid: null,
                    paymentMethodData: {},
                    paymentMethodTypes: {},
                    paymentData: {},
                    addOnsData: [],
                    contactGuid: null,
                    contactData: {},
                    metaData: {},
                    formResult: {},
                    formFields: {}
                };
            }
        }
    },
    data: function () {
        return {
            campaignErrored: false,
            campaignErrorDescription: null,
            campaignLoading: true,
            workflowErrored: false,
            workflowLoading: true
        };
    },
    methods: {

        /**
         * Initialise session request by product
         * @param {object} product 
         * @param {string} serviceType 
         */
        initialiseRequest: function (product, serviceType = '', ignoreDelay) {

            const productData = { ...product }

            //  Ensure session delay counter is enforced
            if (this.sessionDelayCounter > 0 && !ignoreDelay) {

                this.$root.errorResponse("Please wait one moment and try again.");
                return;
            } else {
                this.sessionDelayCounter = 10;
            }

            //  Build request core
            let request = {
                agencyId: this.sessionRequest.agencyId,
                merchantId: this.sessionRequest.merchantId,
                userGuid: this.sessionRequest.userGuid,
                campaignGuid: this.sessionRequest.campaignGuid,
                workflowGuid: this.sessionRequest.workflowGuid,
                serviceType: serviceType,
                externalLeadId: "",
                completionChannel: ""
            };

            //  Data handling
            request = this.filterContactData(request, productData);
            request = this.validateContactData(request);
            request = this.filterMetaData(request, productData);

            //  Type handling
            request = this.filterServiceType(request, productData);
            request = this.filterCompletionChannel(request, productData);
            request = this.filterLeadId(request);

            //  Transactional
            request = this.filterTransactionalData(request, productData);

            //  DataSet specific
            request = this.filterFormResult(request, productData);
            request = this.filterFormFields(request);
            request = this.filterQueryString(request);
            request = this.filterTemporaryServiceType(request);

            //  Final validation
            request = this.filterValidateGatewayRequirements(request, productData);

            //  Debug
            // window.console.log(product);
            // window.console.log(request);
            // window.console.log(request.serviceType);
            // window.console.log(request.agreementData);

            //  Catch error
            if (request.error) {

                window.console.log(productData);
                window.console.log(request);

                this.$root.errorResponse(request.error);
                return;
            }

            //  Debug
            //  window.console.log('OK');

            //  Send request to Onboarding and await session
            this.sendRequest(request);
        },

        /**
         * Define ServiceType
         * @param {object} request 
         * @param {object} product 
         */
        filterServiceType: function (request, product) {

            //  Upgrade from Workflow with ServiceType already defined
            if (this.sessionRequest.workflow.serviceType === 'Upgrade' && request.serviceType !== '') {

                if (request.serviceType === 'ChangePaymentMethod') {
                    request.completionChannel = "PaymentSessionHandler";
                } else {
                    request.completionChannel = "PaymentEngine";
                }

                return request;
            }

            //  SignUp from Workflow
            else if (this.sessionRequest.workflow.serviceType === 'SignUp') {

                //  Single by product
                if (product.paymentType === "Single") {

                    request.serviceType = "SignUpSingle";
                    request.completionChannel = "PaymentSessionHandler";

                }

                //  Recurring by product
                else if (product.paymentType === "Recurring") {

                    request.serviceType = "SignUpRecurring";
                    request.completionChannel = "PaymentSessionHandler";
                }

            } else {

                request.error = 'ServiceType is missing. Please review campaign, workflow and guids';
            }

            return request;
        },

        /**
         * Correct ServiceType
         * @param {object} request
         */
        filterTemporaryServiceType: function (request) {

            if (request.serviceType === 'SubscriptionExpirationRestart') {
                request.serviceType = 'SubscriptionRestart';
            } else if (request.serviceType === 'SubscriptionExpirationHold') {
                request.serviceType = 'SubscriptionHold';
            }

            return request;
        },

        /**
         * Define CompletionChannel
         * @param {object} request 
         * @param {object} product 
         */
        filterCompletionChannel: function (request, product) {

            //  Send SMS
            if (product.sendSms) {

                if (product.paymentMethodType === 'Test') {

                    //  Skip SMS if Test since it is a SMS ..
                    return request;
                } else if (!request.contactData.hasOwnProperty("msisdn")) {

                    request.error = 'Missing phone to complete with SMS';
                    return request;
                } else {

                    request.completionChannel = "SMS";
                }
            }

            return request;
        },

        /**
         * Send request
         * @param {object} request 
         */
        sendRequest: function (request) {

            //  Body
            let body = JSON.stringify(request);

            //  Count session attempts
            this.sessionAttempts = this.sessionAttempts + 1;

            //  Request
            axios
                .post(
                    this.$root.apiUrl + "/session?serviceType=" + request.serviceType,
                    body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken,
                        requestMD5: md5(body)
                    }
                }
                )
                .then(response => {

                    this.sessionStatus = "Committed";

                    //  Emit
                    this.$emit("newSessionStatus", this.sessionStatus);

                    //  Regular sessions
                    if (
                        request.serviceType === "SignUpSingle" ||
                        request.serviceType === "SignUpRecurring" ||
                        request.serviceType === "ChangePaymentMethod"
                    ) {

                        this.checkSessionContinuously(request);
                    }

                    //  Betalingsservice by third party
                    else if (request.serviceType === "SignUpRecurringByBs3rd") {

                        if (request.completionChannel == "SMS") {

                            this.checkBs3rdSessionContinuously();
                        } else {

                            this.sessionStatus = "Completed";

                            this.$root.successResponse("Created", response);

                            //  Emit
                            this.$emit("newSessionStatus", this.sessionStatus);
                        }
                    }

                    //  Third parties
                    else if (
                        request.serviceType === 'SignUpSingleByGiro3rd' ||
                        request.serviceType === 'SignUpRecurringByGiro3rd' ||
                        request.serviceType === 'SignUpSingleByInvoice3rd' ||
                        request.serviceType === 'SignUpRecurringByInvoice3rd' ||
                        request.serviceType === 'SignUpSingleByEAN3rd' ||
                        request.serviceType === 'SignUpRecurringByEAN3rd'
                    ) {

                        this.sessionStatus = "Completed";

                        this.$root.successResponse("Created", response);

                        //  Emit
                        this.$emit("newSessionStatus", this.sessionStatus);
                    }

                    //  Upgrade
                    else if (request.serviceType === "CreateAddOn") {

                        //  Visualise to agent
                        for (var a = 0; a < request.addOnData.length; a++) {
                            this.sessionRequest.addOnsData.push({
                                createdTs: 'just now',
                                name: request.addOnData[a].name,
                                amountTotal: request.addOnData[a].amountTotal
                            });
                        }

                        this.sessionStatus = "Completed";

                        this.$root.successResponse("Added", response);
                    }

                    //  Upgrade
                    else if (request.serviceType === "AgreementUpdate") {

                        this.sessionStatus = "Completed";

                        this.$root.successResponse("Updated", response);
                    }

                    //  Retention
                    else if (request.serviceType === "SubscriptionRestart" || request.serviceType === "SubscriptionExpirationRestart") {

                        //  Visualise to agent
                        this.sessionRequest.subscriptionData.state = 'Active';

                        this.sessionStatus = "Completed";

                        this.sessionRequest.subscriptionData.expiresAfterDate = request.subscriptionData.expiresAfterDate;

                        if (!this.skipToaster) {
                            this.$root.successResponse("Restarted", response);
                        }
                    }

                    //  Retention
                    else if (request.serviceType === "SubscriptionHold" || request.serviceType === "SubscriptionExpirationHold") {

                        //  Visualise to agent
                        this.sessionRequest.subscriptionData.state = 'Inactive';
                        if (request.subscriptionData.holdDescription) {
                            this.sessionRequest.subscriptionData.holdDescription = request.subscriptionData.holdDescription;
                        }
                        this.sessionRequest.subscriptionData.cancelDescription = null;

                        this.sessionStatus = "Completed";

                        if (!this.skipToaster) {
                            this.$root.successResponse("On hold", response);
                        }
                    }

                    //  Retention
                    else if (request.serviceType === "SubscriptionCancel") {

                        //  Visualise to agent
                        this.sessionRequest.subscriptionData.state = 'Inactive';
                        this.sessionRequest.subscriptionData.holdDescription = null;
                        if (request.subscriptionData.cancelDescription) {
                            this.sessionRequest.subscriptionData.cancelDescription = this.sessionRequest.subscriptionData.cancelDescription;
                        }

                        this.sessionStatus = "Completed";

                        this.$root.successResponse("Cancelled", response);
                    }

                    //  Set sessionGuid
                    request.sessionGuid = response.data.sessionGuid;
                    this.sessionRequest.sessionGuid = request.sessionGuid;
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error);
                });
        },

        /**
         * Check session every 2.5 sec.
         * @param {object} request
         */
        checkSessionContinuously: function (request) {

            let checkingSession = setInterval(
                function () {

                    //  Debug
                    window.console.log('Session is ' + this.sessionStatus);

                    if (this.sessionStatus === "Committed") {
                        this.checkSession(request);
                    } else if (
                        this.sessionStatus === "SendingToGateway" ||
                        this.sessionStatus === "SentToGateway"
                    ) {
                        this.checkPaymentSession(request);
                    } else if (
                        this.sessionStatus === "Completed" ||
                        this.sessionStatus === "Rejected" ||
                        this.sessionStatus === "Failed" ||
                        this.sessionStatus === "Aborted"
                    ) {
                        clearInterval(checkingSession);
                    }

                }.bind(this),
                2500
            );
        },

        /**
         * Check session by request
         * @param {object} request
         */
        checkSession: function (request) {

            //  Debug
            window.console.log('Checking Session');

            //  Request
            axios
                .get(
                    this.$root.apiUrl +
                    "/session/" +
                    this.sessionRequest.sessionGuid +
                    "?merchantId=" +
                    encodeURIComponent(this.sessionRequest.merchantId), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                }
                )
                .then(response => {

                    //  Detect request sent to gateway
                    if (response.data.status === "Processing") {
                        this.checkPaymentSession(request);
                    }

                    //  Detect request not yet sent to gateway
                    else {
                        this.sessionStatus = "SendingToGateway";

                        //  Emit
                        this.$emit("newSessionStatus", this.sessionStatus);
                    }
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error, "Session did not respond");
                });
        },

        /**
         * Check Betalingsservice by third party session every 2.5 sec.
         */
        checkBs3rdSessionContinuously: function () {

            let checkingSession = setInterval(
                function () {

                    //  Debug
                    window.console.log('Session is ' + this.sessionStatus);

                    if (
                        this.sessionStatus === "Completed" ||
                        this.sessionStatus === "Aborted"
                    ) {
                        clearInterval(checkingSession);
                    } else {
                        this.checkBs3rdSession();
                    }

                }.bind(this),
                2500
            );
        },

        /**
         * Check Betalingsservice by third party session by request
         */
        checkBs3rdSession: function () {

            //  Debug
            window.console.log('Checking Session');

            //  Request
            axios
                .get(
                    this.$root.apiUrl +
                    "/session/" +
                    this.sessionRequest.sessionGuid +
                    "?merchantId=" +
                    encodeURIComponent(this.sessionRequest.merchantId), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                }
                )
                .then(response => {
                    if (response.data.status === "Completed") {

                        this.sessionStatus = response.data.status;

                        //  Emit
                        this.$emit("newSessionStatus", this.sessionStatus);
                    }
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error, "Session did not respond");
                });
        },

        /**
         * Check payment session by request
         * @param {object} request
         */
        checkPaymentSession: function (request) {

            //  Debug
            window.console.log('Checking Payment Session');

            //  Request
            axios
                .get(
                    this.$root.apiUrl +
                    "/relay/paymentSession/" +
                    this.sessionRequest.sessionGuid +
                    "?merchantId=" +
                    encodeURIComponent(this.sessionRequest.merchantId), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                }
                )
                .then(response => {

                    //  Detect request sent to gateway
                    if (
                        response.data.state === "SentToGateway" &&
                        response.data.redirectToGatewayUrl
                    ) {

                        //  Card
                        if (request.paymentMethodData.paymentMethodType === 'Card') {

                            if (request.completionChannel === 'SMS') {
                                //  Skip, since gateway will be opened by lead via SMS
                            } else {

                                //  Open gateway once in popup
                                if (this.sessionStatus !== "SentToGateway") {
                                    this.iframeGatewayUrl = response.data.redirectToGatewayUrl;
                                    //  this.iframeGatewayHeight = 300; // ePay
                                    this.iframeGatewayHeight = 600; // DIBS
                                }
                            }
                        }

                        //  MobilePay
                        else if (request.paymentMethodData.paymentMethodType === 'MobilePaySubscriptions' || request.paymentMethodData.paymentMethodType === 'MobilePayOnline') {

                            //  Open gateway once in iframe
                            if (this.sessionStatus !== "SentToGateway") {
                                this.iframeGatewayUrl = response.data.redirectToGatewayUrl;
                                this.iframeGatewayHeight = 850;
                            }
                        }

                        //  Betalingsservice
                        else if (request.paymentMethodData.paymentMethodType === 'Betalingsservice') {

                            if (request.completionChannel === 'SMS') {
                                //  Skip, since gateway will be opened by lead via SMS
                            }

                            //  Open gateway once
                            else if (this.sessionStatus !== "SentToGateway") {
                                this.iframeGatewayUrl = response.data.redirectToGatewayUrl;
                                this.iframeGatewayHeight = 560;
                            }
                        }

                        //  DirectDebit
                        else if (request.paymentMethodData.paymentMethodType === 'DirectDebit') {

                            this.iframeGatewayUrl = response.data.redirectToGatewayUrl;
                            this.iframeGatewayHeight = 560;
                        }

                        this.sessionStatus = "SentToGateway";
                    }

                    //  Detect request completed
                    else if (response.data.state === "Complete") {

                        this.iframeGatewayUrl = null;
                        this.sessionStatus = "Completed";

                        //  Visualise to agent
                        if (request.serviceType === "ChangePaymentMethod") {
                            this.sessionRequest.subscriptionData.state = 'Active';
                            this.sessionRequest.paymentMethodData.state = 'Active';
                            this.sessionRequest.paymentMethodData.paymentMethodType = request.paymentMethodData.paymentMethodType;
                        }
                    }

                    //  Detect request rejected
                    else if (response.data.state === "Rejected") {

                        this.iframeGatewayUrl = null;
                        this.sessionStatus = "Rejected";
                    }

                    //  Detect request pending
                    else if (response.data.state === "Pending") {

                        //  Noop
                    }

                    //  Emit
                    this.$emit("newSessionStatus", this.sessionStatus);
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error, "Payment session did not respond");
                });
        },

        /**
         * Abort session
         */
        abortSession: function () {

            this.iframeGatewayUrl = null;
            this.sessionStatus = "Aborted";
            this.$root.successResponse("Aborted");

            //  Emit
            this.$emit("newSessionStatus", this.sessionStatus);
        },

        /**
         * Verify onboarding instance
         */
        verifyInstance: function () {

            //  Verify either merchantId or agencyId confirming that the account is verified
            if (!this.$root.user.merchantId && !this.$root.user.agencyId) {

                //  this.$root.errorResponse("Access not verified");
                // window.console.log('Access not verified');
                return false;
            }

            //  Register agency
            if (this.$root.user.agencyId) {
                this.sessionRequest.agencyId = this.$root.user.agencyId;
            }

            //  Register user
            if (this.$root.user) {
                this.sessionRequest.user = this.$root.user;

                if (this.$root.user.guid) {
                    this.sessionRequest.userGuid = this.$root.user.guid;
                }
            }

            //  Register Campaign and Workflow
            if (this.$route.query.campaignGuid) {
                this.sessionRequest.campaignGuid = this.$route.query.campaignGuid.trim();
                this.registerCampaign(this.sessionRequest.campaignGuid);
            }

            //  Set External Lead ID
            if (this.$route.query.externalLeadId) {
                this.sessionRequest.externalLeadId = this.$route.query.externalLeadId.trim();
            }

            return true;
        },

        /**
         * Register campaign
         * @param {string} campaignGuid 
         */
        registerCampaign: function (campaignGuid) {

            this.sessionRequest.campaignGuid = campaignGuid;

            //  Handle promise or storage
            let campaignHandler = this.getCampaign(this.sessionRequest.campaignGuid);

            //  Promise
            if (typeof campaignHandler.then !== "undefined") {
                campaignHandler.then(response => {
                    if (response.campaignErrored) {
                        this.sessionRequest.campaign = {};
                        this.campaignLoading = false;
                        this.campaignErrored = true;
                        this.campaignErrorDescription = response.campaignErrorDescription;
                    } else {
                        this.sessionRequest.campaign = response.campaign;
                        this.campaignLoading = false;
                        this.campaignErrored = false;
                        this.campaignErrorDescription = null;
                        this.registerWorkflow(this.sessionRequest.campaign.workflowGuid);
                        this.registerMerchantId(this.sessionRequest.campaign.merchantId);
                    }
                });
            }

            //  Storage
            else if (campaignHandler.campaign) {
                this.sessionRequest.campaign = campaignHandler.campaign;
                this.campaignLoading = false;
                this.campaignErrored = false;
                this.campaignErrorDescription = null;
                this.registerWorkflow(this.sessionRequest.campaign.workflowGuid);
                this.registerMerchantId(this.sessionRequest.campaign.merchantId);
            }

            //  Assume error
            else {
                this.campaignLoading = false;
                this.campaignErrored = true;
                this.campaignErrorDescription = null;
            }
        },

        /**
         * Register workflow
         * @param {string} workflowGuid 
         */
        registerWorkflow: function (workflowGuid) {

            this.sessionRequest.workflowGuid = workflowGuid;

            //  Handle promise or storage
            let workflowHandler = this.getWorkflow(this.sessionRequest.workflowGuid);

            //  Promise
            if (typeof workflowHandler.then !== "undefined") {
                workflowHandler.then(response => {
                    if (response.workflowErrored) {
                        this.sessionRequest.workflow = {};
                        this.workflowLoading = false;
                        this.workflowErrored = true;
                    } else {
                        this.sessionRequest.workflow = response.workflow;
                        this.workflowLoading = false;
                        this.workflowErrored = false;
                    }
                });
            }

            //  Storage
            else if (workflowHandler.workflow) {
                this.sessionRequest.workflow = workflowHandler.workflow;
                this.workflowLoading = false;
                this.workflowErrored = false;
            }

            //  Assume error
            else {
                this.workflowLoading = false;
                this.workflowErrored = true;
            }
        },

        /**
         * Register merchantId
         * @param {string} merchantId 
         */
        registerMerchantId: function (merchantId) {

            let merchantHandler = this.getMerchant(merchantId);

            //  Promise
            if (typeof merchantHandler.then !== "undefined") {
                merchantHandler.then(response => {
                    if (response.merchantErrored) {
                        this.$root.merchant = {};
                        this.sessionRequest.merchantId = null;
                    } else {
                        this.$root.merchant = response.merchant;
                        this.sessionRequest.merchantId = response.merchant.merchantId;

                        this.registerEntities();
                    }
                });
            }

            //  Storage
            else if (merchantHandler.merchant) {
                this.$root.merchant = merchantHandler.merchant;
                this.sessionRequest.merchantId = merchantHandler.merchant.merchantId;

                this.registerEntities();
            }
        },

        /**
         * Register relevant entities
         */
        registerEntities: function () {

            if (this.sessionRequest.merchantId) {

                //  Set SubscriptionGuid by query.parameter
                if (this.$route.query.subscriptionGuid) {
                    this.sessionRequest.subscriptionGuid = this.$route.query.subscriptionGuid.trim();
                    this.applySubscriptionToSession();
                }

                //  Set SubscriptionGuid by query.parameter - supporting typos
                else if (this.$route.query.pre_subscriptionGuid) {
                    this.sessionRequest.subscriptionGuid = this.$route.query.pre_subscriptionGuid.trim();
                    this.applySubscriptionToSession();
                }

                //  Set ContactGuid by query-parameter
                else if (this.$route.query.contactGuid) {
                    this.sessionRequest.contactGuid = this.$route.query.contactGuid.trim();
                    this.applyContactToSession();
                }

                //  Set ContactGuid by query-parameter - supporting typos
                else if (this.$route.query.pre_contactGuid) {
                    this.sessionRequest.contactGuid = this.$route.query.pre_contactGuid.trim();
                    this.applyContactToSession();
                }
            }
        }
    }
}