<template>
  <div v-if="!getContactPhone" class="row">
    <div class="col-sm-6 control-input">
      <label for="msisdn">Enter phone for Test SMS</label>
      <input
        type="number"
        id="msisdn"
        class="uik-input__input"
        minlength="6"
        maxlength="14"
        autocomplete="nope"
        placeholder="Insert required phone"
        aria-label="Telefon-nummer til Test-betalingsmetode."
        required
        v-bind:class="[
          product.msisdn && product.msisdn.length > 0 ? 'not-empty' : 'empty',
        ]"
        v-model.trim="product.msisdn"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "OnboardingPaymentMethodTypeSelectionTest",
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getContactPhone: function () {
      let contactPhoneField =
        this.sessionRequest.workflow.contactDataFields.filter(function (field) {
          if (field.name === "msisdn" && field.value) {
            return field;
          }
        });

      if (contactPhoneField.length > 0) {
        return contactPhoneField[0].value;
      }

      return null;
    },
  },
});
</script>
