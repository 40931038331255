var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group-dawa"},[_c('label',{staticClass:"control-label col-sm-3",attrs:{"for":_vm.contactData.name}},[_vm._v(" "+_vm._s(_vm.contactData.label)+" ")]),_c('div',{staticClass:"control-input col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.contactData.value),expression:"contactData.value",modifiers:{"trim":true}}],staticClass:"uik-input__input",class:[
          _vm.contactData.value && _vm.contactData.value.length > 0
            ? 'not-empty'
            : 'empty',
          _vm.contactData.validity ? _vm.contactData.validity : '',
        ],attrs:{"type":"text","id":_vm.contactData.name,"autocomplete":"nope","required":_vm.contactData.required ? true : false,"placeholder":_vm.contactData.attr && _vm.contactData.attr.placeholder
        ? _vm.contactData.attr.placeholder
        : false},domProps:{"value":(_vm.contactData.value)},on:{"keydown":_vm.onKeyDown,"keyup":_vm.onKeyUp,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contactData, "value", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"validator-icon"}),(_vm.acceptingInvalidAddress)?_c('div',{staticClass:"dawa-suggestions"},[_c('button',{staticClass:"uik-btn__base btn btn-clear-address",attrs:{"type":"button"},on:{"click":function($event){return _vm.clearAddress()}}},[_vm._v(" Clear the address ")]),_c('button',{staticClass:"uik-btn__base btn btn-accept-address",attrs:{"type":"button"},on:{"click":function($event){return _vm.acceptInvalidAddress()}}},[_vm._v(" Accept the address ")]),(_vm.countryCode == 'DK')?_c('p',[_vm._v("No suggestions found")]):_c('p',[_vm._v("Address not found")])]):_c('div',{staticClass:"dawa-suggestions"},[_c('button',{staticClass:"uik-btn__base btn btn-clear-address",attrs:{"type":"button"},on:{"click":function($event){return _vm.clearAddress()}}},[_vm._v(" Clear the address ")]),(_vm.dawaSuggestions.length > 0)?_c('button',{staticClass:"uik-btn__base btn btn-accept-address",attrs:{"type":"button"},on:{"click":function($event){return _vm.acceptInvalidAddress()}}},[_vm._v(" Accept the address ")]):_vm._e(),(_vm.dawaSuggestions.length > 0)?_c('p',[_vm._v("Select an address")]):_vm._e(),(_vm.dawaSuggestions.length > 0)?_c('div',{staticClass:"inner",style:({
        height:
          (_vm.dawaSuggestions.length * 30 + 10 > 280
            ? 280
            : _vm.dawaSuggestions.length * 30 + 10) + 'px',
      })},[_c('ul',_vm._l((_vm.dawaSuggestions),function(dawaSuggestion){return _c('li',{key:dawaSuggestion.id},[_c('button',{class:dawaSuggestion.type === 'adresse'
              ? 'dawa-valid'
              : 'dawa-fuzzy',attrs:{"type":"button","title":dawaSuggestion.type === 'adresse'
                ? 'Valid unique address'
                : 'Suggestion since address is not unique'},on:{"click":function($event){return _vm.selectSuggestion(dawaSuggestion)}}},[_vm._v(" "+_vm._s(dawaSuggestion.fullAddress)+" "),(dawaSuggestion.type === 'adresse')?_c('span',[_vm._v("Valid")]):_c('span',[_vm._v("Suggestion")])])])}),0)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }