export const formats = {
    filters: {

        /**
         * Get agency name
         * @param {object} agency 
         */
        humanReadableAgency: function (agency) {

            if (agency.name) {
                return agency.name;
            } else {
                return agency.agencyId;
            }
        },

        /**
         * Make merchantId human readable
         * @param {string} merchantId
         * @param {Array} merchants
         */
        humanReadableMerchantId: function (merchantId, merchants) {

            if (merchants && merchants.length > 0) {
                for (var i = 0; i < merchants.length; i++) {
                    if (merchantId === merchants[i].merchantId) {
                        return merchants[i].name;
                    }
                }
            }

            return merchantId;
        },

        /**
         * Make ServiceType human readable
         * @param {string} serviceType 
         */
        humanReadableServiceType: function (serviceType) {

            if (serviceType === 'SignUp') {
                return 'Sign-up';
            } else if (serviceType === 'SignUpSingle') {
                return 'Sign-up single';
            } else if (serviceType === 'SignUpRecurring') {
                return 'Sign-up recurring';
            } else if (serviceType === 'SignUpRecurringByBs3rd') {
                return 'Sign-up recurring with third party';
            } else if (serviceType === 'SignUpSingleByGiro3rd') {
                return 'Sign-up single with third party';
            } else if (serviceType === 'SignUpRecurringByGiro3rd') {
                return 'Sign-up recurring with third party';
            } else if (serviceType === 'SignUpSingleByInvoice3rd') {
                return 'Sign-up single with third party';
            } else if (serviceType === 'SignUpRecurringByInvoice3rd') {
                return 'Sign-up recurring with third party';
            } else if (serviceType === 'SignUpRecurringByEAN3rd') {
                return 'Sign-up single with third party';
            } else if (serviceType === 'SignUpSingleByEAN3rd') {
                return 'Sign-up recurring with third party';
            } else if (serviceType === 'AgreementUpdate') {
                return 'Update agreement';
            } else if (serviceType === 'CreateAddOn') {
                return 'Create add-on';
            } else if (serviceType === 'ChangePaymentMethod') {
                return 'Change payment method';
            } else if (serviceType === 'SubscriptionHold') {
                return 'Subscription on hold';
            } else if (serviceType === 'SubscriptionExpirationHold') {
                return 'Subscription on hold to set expiraiton';
            } else if (serviceType === 'SubscriptionCancel') {
                return 'Subscription cancelled';
            } else if (serviceType === 'SubscriptionRestart') {
                return 'Subscription restarted';
            } else if (serviceType === 'SubscriptionExpirationRestart') {
                return 'Subscription restarted to set expiration';
            } else {
                return serviceType;
            }
        },

        /**
         * Make paymentMethodType human readable
         * @param {string} paymentMethodType 
         */
        humanReadablePaymentMethodType: function (paymentMethodType) {

            if (paymentMethodType === 'MobilePaySubscriptions') {
                return 'MobilePay Subscriptions';
            }

            return paymentMethodType;
        },

        /**
         * Make contact property human readable
         * @param {string} propertyName 
         */
        humanReadableContactProperty: function (propertyName) {

            if (propertyName === 'name') {
                return 'Name';
            } else if (propertyName === 'firstName') {
                return 'First name';
            } else if (propertyName === 'lastName') {
                return 'Last name';
            } else if (propertyName === 'email') {
                return 'Email';
            } else if (propertyName === 'msisdn') {
                return 'Phone';
            } else if (propertyName === 'nationalId') {
                return 'National ID';
            } else if (propertyName === 'businessCode') {
                return 'Business number';
            } else if (propertyName === 'companyName') {
                return 'Business name';
            } else if (propertyName === 'eanNumber') {
                return 'EAN number';
            } else if (propertyName === 'dawaAddress') {
                return 'Address';
            } else if (propertyName === 'address') {
                return 'Address';
            } else if (propertyName === 'address2') {
                return 'Address line 2';
            } else if (propertyName === 'postCode') {
                return 'Postcode';
            } else if (propertyName === 'city') {
                return 'City';
            } else if (propertyName === 'countryCode') {
                return 'Country';
            } else if (propertyName === 'birthDate') {
                return 'Birthdate';
            } else if (propertyName === 'externalId') {
                return 'External ID';
            } else if (propertyName === 'contactGuid') {
                return 'Guid';
            }

            return propertyName;
        },

        /**
         * Make urlShortener human readable
         * @param {string} urlShortener 
         */
        humanReadableUrlShortener: function (urlShortener) {

            if (urlShortener === 'GIIV') {
                return 'https://giiv.dk?xxx';
            } else if (urlShortener === 'KORTL_INK') {
                return 'https://kortl.ink?xxx';
            }

            return urlShortener;
        },

        /**
         * Lowercase a string
         * @param {string} string 
         */
        toLowerCase: function (string) {

            return string.toLowerCase();
        },

        /**
         * Format schedule type
         * @param {string} string 
         */
        formatScheduleType: function (string) {

            return string.replace("First", "").toLowerCase();
        },

        /**
         * Format birthDate by datePicker
         * @param {object} datePicker 
         */
        formatBirthDatePicker: function (datePicker) {
            //  2020-01-01
            if (datePicker.startDate && datePicker.endDate) {
                return (
                    '<span class="date">' +
                    datePicker.startDate.getFullYear() +
                    "-" +
                    ("0" + (datePicker.startDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + datePicker.startDate.getDate()).slice(-2) +
                    '</span>'
                );
            }

            return "";
        },

        /**
         * Format datePicker result as range
         * @param {object} datePicker 
         */
        formatDateRangePicker: function (datePicker) {
            //  2020-01-01 - 2020-12-31
            if (datePicker.startDate && datePicker.endDate) {
                return (
                    '<span class="date">' +
                    datePicker.startDate.getFullYear() +
                    "-" +
                    ("0" + (datePicker.startDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + datePicker.startDate.getDate()).slice(-2) +
                    '</span> - <span class="date">' +
                    datePicker.endDate.getFullYear() +
                    "-" +
                    ("0" + (datePicker.endDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + datePicker.endDate.getDate()).slice(-2) +
                    "</span>"
                );
            }

            return "";
        },

        /**
         * Format datePicker result as date
         * @param {object} datePicker 
         */
        formatDatePicker: function (datePicker) {
            //  2020-01-01
            if (datePicker.startDate) {
                return (
                    '<span class="date">' +
                    datePicker.startDate.getFullYear() +
                    "-" +
                    ("0" + (datePicker.startDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + datePicker.startDate.getDate()).slice(-2) +
                    "</span>"
                );
            }

            return "";
        },

        /**
         * Format date
         * @param {string} timestamp 
         */
        formatDate: function (timestamp) {

            //  2020-01-01
            if (timestamp) {
                return '<span class="Ts">' + timestamp.substring(0, 10) + "</span>";
            }
            return "";
        },

        /**
         * Format date with color
         * @param {string} timestamp 
         */
        formatDateWithColor: function (timestamp) {

            let color = "";
            if (new Date() > new Date(timestamp.substring(0, 10))) {
                color = "red";
            } else {
                color = "green";
            }

            //  2020-01-01
            if (timestamp) {
                return '<span class="Ts ' + color + '">' + timestamp.substring(0, 10) + "</span>";
            }
            return "";
        },

        /**
         * Format timestamp
         * @param {string} timestamp 
         */
        formatTime: function (timestamp) {
            //  2020-01-01
            //  00:00:00
            if (timestamp) {
                return (
                    '<span class="Ts" title="' +
                    timestamp.substring(0, 19) +
                    '">' +
                    timestamp.substring(0, 10) +
                    " <span>" +
                    timestamp.substring(11, 19) +
                    "</span></span>"
                );
            }
            return "";
        },

        /**
         * Format boolean
         * @param {boolean} boolean 
         */
        formatBoolean: function (boolean) {

            if (boolean === true) {
                return 'Yes';
            } else {
                return 'No';
            }
        },

        /**
         * Format amount
         * @param {integer} number 
         */
        formatAmount: function (number) {

            var n = parseFloat(number);

            return n.toLocaleString();
        }
    },
    methods: {

        /**
         * Make schedule human readable
         * @param {object} agreementData
         * @param {string} defaultString 
         */
        humanReadableSchedule: function (agreementData, defaultString = '') {

            if (agreementData.hasOwnProperty("scheduleType") && agreementData.scheduleType) {

                //  Weekly
                if (
                    agreementData.scheduleType === "Weekly" ||
                    agreementData.scheduleType === "Manual"
                ) {
                    return agreementData.scheduleType;
                }

                //  Custom weekly
                else if (
                    agreementData.scheduleType === "Custom" &&
                    agreementData.scheduleCalendarUnit === "Week"
                ) {

                    //  Defined schedule
                    if (agreementData.scheduleBaseTier || agreementData.scheduleFixedDay) {
                        return (
                            this.phraseFrequency(agreementData) +
                            " " +
                            this.phraseSchedule(agreementData)
                        );
                    }

                    //  Only frequency
                    else if (agreementData.scheduleCalendarUnit && agreementData.scheduleEveryOther) {
                        return this.phraseFrequency(agreementData);
                    }

                    //  Default
                    else {
                        return defaultString;
                    }
                }

                //  Everything else
                else {

                    //  Defined months
                    if (agreementData.hasScheduleSelectedSet || agreementData.scheduleSelectedSet) {
                        return this.phraseSchedule(agreementData);
                    }

                    //  Defined schedule
                    else if (agreementData.scheduleBaseTier || agreementData.scheduleFixedDay) {
                        return (
                            this.phraseFrequency(agreementData) +
                            ", " +
                            this.phraseSchedule(agreementData)
                        );
                    }

                    //  Only frequency
                    else if (agreementData.scheduleCalendarUnit && agreementData.scheduleEveryOther) {
                        return this.phraseFrequency(agreementData);
                    }

                    //  Default
                    else {
                        return defaultString;
                    }
                }
            }

            return defaultString;
        },

        /**
         * Return simple frequency statement
         * @param {object} agreementData
         */
        phraseFrequency: function (agreementData) {
            let f = "";

            if (agreementData.hasOwnProperty("scheduleType")) {

                if (
                    agreementData.hasOwnProperty("hasScheduleSelectedSet") ||
                    agreementData.hasOwnProperty("scheduleSelectedSet")
                ) {
                    f = this.$root.strings._Frequency_SelectedMonths;
                } else if (agreementData.scheduleType === "Weekly") {
                    f = this.$root.strings._Frequency_Weekly;
                } else if (agreementData.scheduleType === "Monthly") {
                    f = this.$root.strings._Frequency_Monthly;
                } else if (agreementData.scheduleType === "Quarterly") {
                    f = this.$root.strings._Frequency_Quarterly;
                } else if (agreementData.scheduleType === "Halfyearly") {
                    f = this.$root.strings._Frequency_Halfyearly;
                } else if (agreementData.scheduleType === "Yearly") {
                    f = this.$root.strings._Frequency_Yearly;
                } else if (agreementData.scheduleType === "MonthlyFirst") {
                    f = this.$root.strings._Frequency_Monthly;
                } else if (agreementData.scheduleType === "QuarterlyFirst") {
                    f = this.$root.strings._Frequency_Quarterly;
                } else if (agreementData.scheduleType === "HalfyearlyFirst") {
                    f = this.$root.strings._Frequency_Halfyearly;
                } else if (agreementData.scheduleType === "YearlyFirst") {
                    f = this.$root.strings._Frequency_Yearly;
                } else if (agreementData.scheduleType === "Custom") {

                    if (agreementData.scheduleCalendarUnit === "Month") {
                        if (agreementData.scheduleEveryOther === 1) {
                            f = "Every month";
                        } else {
                            f =
                                "Every " +
                                this.getGetOrdinal(agreementData.scheduleEveryOther) +
                                " month";
                        }
                    } else if (agreementData.scheduleCalendarUnit === "Week") {
                        if (agreementData.scheduleEveryOther === 1) {
                            f = "Every week";
                        } else {
                            f =
                                "Every " +
                                this.getGetOrdinal(agreementData.scheduleEveryOther) +
                                " week";
                        }
                    }
                } else if (agreementData.scheduleType === "Manual") {
                    f = this.$root.strings._Frequency_Manual;
                }
            }

            return f;
        },

        /**
         * Return descriptive schedule statement
         * @param {object} agreementData
         */
        phraseSchedule: function (agreementData) {
            let s = "";

            if (agreementData.hasOwnProperty("scheduleType")) {

                //  scheduleType : Manual
                if (agreementData.scheduleType === "Manual") {
                    return this.$root.strings._Frequency_Manual;
                }

                //  scheduleType : Weekly
                else if (agreementData.scheduleType === "Weekly") {
                    return this.$root.strings._Frequency_Weekly;
                }

                //  Agreement with scheduleSelectedSet
                else if (agreementData.hasOwnProperty("hasScheduleSelectedSet") || agreementData.hasOwnProperty("scheduleSelectedSet")) {

                    return 'Selected months: ' + this.phraseScheduleSelectedSet(agreementData);
                }

                //  scheduleType : Custom
                else if (agreementData.scheduleType === "Custom") {

                    //  Month
                    if (agreementData.scheduleCalendarUnit === "Month") {

                        s =
                            this.$root.strings._OnThe +
                            " " +
                            this.getGetOrdinal(agreementData.scheduleFixedDay) +
                            " " +
                            this.$root.strings._OfEvery +
                            " ";

                        //  Append every other (skipping unit) if other than 1
                        if (agreementData.scheduleEveryOther !== 1) {
                            s += this.getGetOrdinal(agreementData.scheduleEveryOther) + " ";
                        }

                        //  Append calendar unit
                        s += this.translateCalendarUnit(
                            agreementData.scheduleCalendarUnit
                        ).toLowerCase();

                        //  Append base tier (the offset month) if other than 1 = January
                        if (agreementData.scheduleBaseTier !== 1) {
                            s +=
                                ", " +
                                this.$root.strings._StartingFrom +
                                " " +
                                this.translateMonthAbbr(agreementData.scheduleBaseTier);
                        }
                    }

                    //  Week
                    else if (agreementData.scheduleCalendarUnit === "Week") {

                        if (agreementData.scheduleFixedDay === 1) {
                            s = "on " + this.$root.strings._Monday + "s";
                        } else if (agreementData.scheduleFixedDay === 2) {
                            s = "on " + this.$root.strings._Tuesday + "s";
                        } else if (agreementData.scheduleFixedDay === 3) {
                            s = "on " + this.$root.strings._Wednesday + "s";
                        } else if (agreementData.scheduleFixedDay === 4) {
                            s = "on " + this.$root.strings._Thursday + "s";
                        } else if (agreementData.scheduleFixedDay === 5) {
                            s = "on " + this.$root.strings._Friday + "s";
                        } else if (agreementData.scheduleFixedDay === 6) {
                            s = "on " + this.$root.strings._Saturday + "s";
                        } else if (agreementData.scheduleFixedDay === 7) {
                            s = "on " + this.$root.strings._Sunday + "s";
                        }
                    }

                }

                //  Known frequencies
                else {

                    if (agreementData.scheduleFixedDay && agreementData.scheduleBaseTier) {
                        return this.$root.strings._Every +
                            " " +
                            this.getGetOrdinal(agreementData.scheduleFixedDay) +
                            " " +
                            this.$root.strings._Of +
                            " " +
                            this.translateMonthAbbr(agreementData.scheduleBaseTier);
                    } else if (agreementData.scheduleFixedDay) {
                        return this.$root.strings._OnThe +
                            " " +
                            this.getGetOrdinal(agreementData.scheduleFixedDay);
                    }
                }
            }

            return s;
        },

        /**
         * @param {object} agreementData
         */
        phraseScheduleSelectedSet: function (agreementData) {

            var s = '';

            if (agreementData.scheduleCalendarUnit === 'Month') {


                if (agreementData.scheduleFixedDay) {
                    s = 'on the ' + this.getGetOrdinal(agreementData.scheduleFixedDay) + ' of';
                } else {
                    s = 'on the ' + this.getGetOrdinal(1) + ' of';
                }

                var months = [];

                //  Parse JSON
                if (agreementData.scheduleSelectedSet) {
                    months = JSON.parse(agreementData.scheduleSelectedSet);
                }

                //  Build array of months
                else if (agreementData.hasScheduleSelectedSet) {

                    if (agreementData.scheduleSelectSet_1) {
                        months.push(1);
                    }
                    if (agreementData.scheduleSelectSet_2) {
                        months.push(2);
                    }
                    if (agreementData.scheduleSelectSet_3) {
                        months.push(3);
                    }
                    if (agreementData.scheduleSelectSet_4) {
                        months.push(4);
                    }
                    if (agreementData.scheduleSelectSet_5) {
                        months.push(5);
                    }
                    if (agreementData.scheduleSelectSet_6) {
                        months.push(6);
                    }
                    if (agreementData.scheduleSelectSet_7) {
                        months.push(7);
                    }
                    if (agreementData.scheduleSelectSet_8) {
                        months.push(8);
                    }
                    if (agreementData.scheduleSelectSet_9) {
                        months.push(9);
                    }
                    if (agreementData.scheduleSelectSet_10) {
                        months.push(10);
                    }
                    if (agreementData.scheduleSelectSet_11) {
                        months.push(11);
                    }
                    if (agreementData.scheduleSelectSet_12) {
                        months.push(12);
                    }
                }

                for (var monthNo in months) {

                    if (parseInt(monthNo) === 0) {
                        s += ' ' + this.translateMonthAbbr(months[monthNo]);
                    } else if (parseInt(monthNo) === (months.length - 1)) {
                        s += ' and ' + this.translateMonthAbbr(months[monthNo]);
                    } else {
                        s += ', ' + this.translateMonthAbbr(months[monthNo]);
                    }
                }

            } else if (agreementData.scheduleCalendarUnit === 'Week') {

                //  TODO - explain this
                s = '';
            }

            return s;
        },

        /**
         * @param {object} product 
         * @returns array
         */
        convertScheduleSelectedSetDefinitionToArray: function (product) {

            var scheduleSelectedSet = [];

            if (product.scheduleSelectSet_1) {
                scheduleSelectedSet.push(1);
            }
            if (product.scheduleSelectSet_2) {
                scheduleSelectedSet.push(2);
            }
            if (product.scheduleSelectSet_3) {
                scheduleSelectedSet.push(3);
            }
            if (product.scheduleSelectSet_4) {
                scheduleSelectedSet.push(4);
            }
            if (product.scheduleSelectSet_5) {
                scheduleSelectedSet.push(5);
            }
            if (product.scheduleSelectSet_6) {
                scheduleSelectedSet.push(6);
            }
            if (product.scheduleSelectSet_7) {
                scheduleSelectedSet.push(7);
            }
            if (product.scheduleSelectSet_8) {
                scheduleSelectedSet.push(8);
            }
            if (product.scheduleSelectSet_9) {
                scheduleSelectedSet.push(9);
            }
            if (product.scheduleSelectSet_10) {
                scheduleSelectedSet.push(10);
            }
            if (product.scheduleSelectSet_11) {
                scheduleSelectedSet.push(11);
            }
            if (product.scheduleSelectSet_12) {
                scheduleSelectedSet.push(12);
            }

            return scheduleSelectedSet;
        },

        /**
         * @param {object} product 
         * @returns string
         */
        convertScheduleSelectedSetDefinitionToString: function (product) {

            var scheduleSelectedSet = this.convertScheduleSelectedSetDefinitionToArray(product),
                s = "[";

            for (let i = 0; i < scheduleSelectedSet.length; i++) {

                if (i + 1 == scheduleSelectedSet.length) {
                    s += scheduleSelectedSet[i];
                } else {
                    s += scheduleSelectedSet[i] + ",";
                }
            }

            s += "]";

            return s;
        },

        /**
         * Helper - ordinal
         */
        getGetOrdinal: function (i) {
            if (i === "") {
                return "";
            }

            var j = i % 10,
                k = i % 100;
            if (j === 1 && k !== 11) {
                return i + "st";
            } else if (j === 2 && k !== 12) {
                return i + "nd";
            } else if (j === 3 && k !== 13) {
                return i + "rd";
            }
            return i + "th";
        },

        /**
         * Helper - translator
         */
        translateCalendarUnit: function (string) {
            if ("_CalendarUnit_" + string in this.$root.strings) {
                return this.$root.strings["_CalendarUnit_" + string];
            }

            return string;
        },

        /**
         * Helper - translator
         */
        translateMonthAbbr: function (m) {
            if (m === 1) {
                return this.$root.strings._January;
            } else if (m === 2) {
                return this.$root.strings._February;
            } else if (m === 3) {
                return this.$root.strings._March;
            } else if (m === 4) {
                return this.$root.strings._April;
            } else if (m === 5) {
                return this.$root.strings._May;
            } else if (m === 6) {
                return this.$root.strings._June;
            } else if (m === 7) {
                return this.$root.strings._July;
            } else if (m === 8) {
                return this.$root.strings._August;
            } else if (m === 9) {
                return this.$root.strings._September;
            } else if (m === 10) {
                return this.$root.strings._October;
            } else if (m === 11) {
                return this.$root.strings._November;
            } else if (m === 12) {
                return this.$root.strings._December;
            }

            return m;
        },

        /**
         * Format date to YYYY-MM-DD
         * @param {string|Date} tS 
         */
        formatDateToYMD: function (tS) {

            let dt = new Date(tS),
                dd = String(dt.getDate()).padStart(2, "0"),
                mm = String(dt.getMonth() + 1).padStart(2, "0"),
                yyyy = dt.getFullYear();

            return yyyy + '-' + mm + '-' + dd;
        },

        /**
         *  Validate email address
         * @param {string} email
         */
        isEmailValid: function (email) {

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return re.test(String(email).toLowerCase());
        },

        /**
         *  Validate National ID
         * @param {string} nationalId
         */
        isNationalIdValid: function (nationalId) {

            if (!nationalId) {
                return false;
            }

            //  Sanitize string to numbers only
            var res = nationalId.replace(/[^0-9]/gi, "");

            //  Validate length
            if (res.length !== 10) {
                return false;
            } else if (res === "0000000000") {
                return false;
            }

            //  Validate day, due to valid CPR like: 3307774126
            var d = parseInt(res.substr(0, 2));
            if (d < 1 || d > 31) {
                return false;
            }

            //  Validate month, due to valid CPR like: 0728774126
            var m = parseInt(res.substr(2, 2));
            if (m < 1 || m > 12) {
                return false;
            }

            var validDates = [
                '010160',
                '010162',
                '010164',
                '010165',
                '010166',
                '010167',
                '010169',
                '010170',
                '010174',
                '010175',
                '010178',
                '010180',
                '010181',
                '010182',
                '010183',
                '010184',
                '010185',
                '010186',
                '010187',
                '010188',
                '010189',
                '010190',
                '010191',
                '010192',
                '010194',
                '010195'
            ],
                value = res.substr(0, 6);

            if (this.arrayContains(validDates, value)) {
                return true;
            }

            var cpr_arr = res.split(""),
                mul_arr = "4327654321".split(""),
                sum = 0;

            for (var i = 0; i < cpr_arr.length; i++) {
                sum += cpr_arr[i] * mul_arr[i];
            }

            if (sum % 11 === 0) {
                return true;
            } else {
                return false;
            }
        },

        /**
         *  Validate EAN number
         * @param {string} eanNumber
         */
        isEanNumberValid: function (eanNumber) {

            if (!eanNumber) {
                return false;
            }

            var valid = eanNumber.match(/^\d{13}$/);

            valid = valid && (parseInt(eanNumber.substr(12)) === this.checkDigit(eanNumber.substr(0, 12)));

            return valid;
        },

        /**
         * Check for value in array
         * @param {array} a
         * @param {string} obj
         */
        arrayContains: function (a, obj) {
            for (var i = 0; i < a.length; i++) {
                if (a[i] === obj) {
                    return true;
                }
            }
            return false;
        },

        /**
         * Check digit
         * @param {int} param 
         */
        checkDigit: function (param) {

            var sum = 0,
                odd = true;

            for (var i = 11; i >= 0; i--) {
                var val = parseInt(param.substr(i, 1));
                val *= (odd ? 3 : 1);
                odd = !odd;
                sum += val;
            }

            var check = 10 - (sum % 10);
            return (check == 10 ? 0 : check);
        }
    }
}